import { initializeApp, getApps, FirebaseApp } from 'firebase/app'
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions'

const getFirebaseApp = (): FirebaseApp => {
  if (getApps().length) {
    return getApps()[0];
  }

  const isBrowser = typeof window !== 'undefined'
  const config = isBrowser && location.host === 'forms.omsister.com'
    ? {
      apiKey: 'AIzaSyByFD18plX4Px54AgitSI9TMqLVjL_8RkU',
      authDomain: 'mypage-prod.firebaseapp.com',
      databaseURL: 'https://mypage-prod.firebaseio.com',
      projectId: 'mypage-prod',
      storageBucket: 'mypage-prod.appspot.com',
      messagingSenderId: '129113667337',
      appId: '1:129113667337:web:e897dbaa7459fa6b2e05d8',
      measurementId: 'G-JVH2JM72VN',
    }
    : {
      apiKey: 'AIzaSyDXv1kP_WGMqtNnlWjqELO7N0yPr3KHsxc',
      authDomain: 'selan-omsister-mypage.firebaseapp.com',
      databaseURL: 'https://selan-omsister-mypage.firebaseio.com',
      projectId: 'selan-omsister-mypage',
      storageBucket: 'selan-omsister-mypage.appspot.com',
      messagingSenderId: '800289779629',
      appId: '1:800289779629:web:b8ff6bb37600926e567b24',
      measurementId: 'G-V96HC48J7P',
    }

  const app = initializeApp(config)

  const functions = getFunctions(app)
  if (process.env.NODE_ENV !== 'production') {
    connectFunctionsEmulator(functions, 'localhost', 5001)
  }

  return app;
}

const firebaseApp = getFirebaseApp();
export default firebaseApp;
import Vue from "vue";
import "./plugins/composition-api";
import vuetify from "./plugins/vuetify";
import router from "./router";
import VueGtm from "@gtm-support/vue2-gtm";
import App from "@/App.vue";

Vue.config.productionTip = false;

import "@/assets/style.scss";

import a8Tracking from "./plugins/a8-tracking";
Vue.use(a8Tracking);

Vue.use(VueGtm, {
  id: "GTM-W7Z9BM2T",
  enabled: true,
  debug: process.env.NODE_ENV === "development",
  loadScript: true,
  vueRouter: router,
  ignoredViews: [],
  trackOnNextTick: false,
});

new Vue({
  vuetify,
  router,
  render: (h: Vue.CreateElement) => h(App),
}).$mount("#app");

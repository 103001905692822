import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: { title: "お申込み(1/5)", desc: "" },
  },
  {
    path: "/forms2",
    name: "Forms2",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Forms2.vue"),
    meta: { title: "お申込み(2/5)", desc: "" },
  },
  {
    path: "/forms3",
    name: "Forms3",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Forms3.vue"),
    meta: { title: "お申込み(3/5)", desc: "" },
  },
  {
    path: "/forms4",
    name: "Forms4",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Forms4.vue"),
    meta: { title: "お申込み(4/5)", desc: "" },
  },
  {
    path: "/complete",
    name: "Complete",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Complete.vue"),
    meta: { title: "お申込み完了", desc: "" },
  },
  // Trial
  {
    path: "/trial",
    name: "Trial",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Trial.vue"),
    meta: { title: "無料 Online 体験レッスンへのお申込み", desc: "" },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
});

Sentry.init({
  Vue,
  dsn: "https://ceaca0b01ba5453e8f7039f19c365466@o317286.ingest.sentry.io/6073827",
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: [
        "localhost",
        "selan-omsister-forms-dev.web.app",
        "forms.omsister.com",
        /^\//,
      ],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

export default router;
